import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Carousel from 'nuka-carousel';
import About from './About'
import Contact from './Contact'
// import Menu from './Menu'
import AutoMenu from '../../react/AutoMenu'
import Philosophy from './Pages/Philosophy'
import Nutrition from './Pages/Nutrition'
import Menu from './Menu'
import Catering from './Catering'
import Wine from './Wine'
import GiftCards from './GiftCards'
// import Spirits from './Spirits'
// import Delivery from './Delivery'
import './index.scss'
import './ThemeToggle/theme_night.scss'
// import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}



export const customOrderAppPaths = ["/", "/order"]

export const pages = [
  {path: "/order", external: true, label: "Order" },
  // {path: "/wine", label: "Alcohol", component: Wine },
  // {path: "/gift-card", label: "Gift Card", component: GiftCards },
  // {path: "/gift-card/:status", hidden: true,  label: "GiftCards", component: GiftCards },
  {path: "/menu", label: "Menu", component: Menu },
  {path: "/menu.html", hidden: true, label: "Menu", component: () => <Redirect to="/menu"></Redirect>},
  {path: "/public/siri/catering-menu.pdf", label: "Catering", external: true, component: Catering },
  // {path: "/public/siri/private-room-price.pdf", label: "Private Room", external: true, component: Catering },

]

export class SiteNav extends PureComponent {
  render() {

    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      <ThemeToggle></ThemeToggle>
    </nav>
  )}
}

const quickLinks = [
  // { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



export function Header () {

    const {themeName} = useContext(ThemeContext)
    const latimeslogo = (themeName === 'dark') ?
      "https://afag.imgix.net/hayats-kitchen/la-times-logo.png?h=80"
      :
     "https://afag.imgix.net/hayats-kitchen/la-times-logo.png?h=80&invert=true"
    return (
      <div className="landing-page-wrapper">



        <div className="carousel-hero">
          <Carousel
            className="carousel"
            autoplay={true}
            slideWidth={1}
            wrapAround={true}
            >
              {/* <img src="https://afag.imgix.net/sidewalk-grill/hero-1.jpg?h=450&w=1000&fit=crop&auto=format"
                alt="assorted mediterraneon food on table"
               /> */}
            {/* <img src="https://afag.imgix.net/electric-lotus/hero.jpg?h=450&w=1000&fit=crop&auto=format"
              alt="indian cuisine on dishes"
             />
            <img src="https://afag.imgix.net/electric-lotus/hero-1.jpg?h=450&w=1000&fit=crop&auto=format"
              alt="indian spices"
             />
             <img src="https://afag.imgix.net/electric-lotus/hero.jpg?h=450&w=1000&fit=crop&auto=format"
               alt="indian cuisine on dishes"
              /> */}
            <img src="https://afag.imgix.net/siri-thai/banner.jpg?h=450&w=1140&fit=crop&auto=format"
              alt="sample dish"
             />
            <img src="https://afag.imgix.net/siri-thai/large-seafood-soup-1.jpg?h=450&w=1140&fit=crop&auto=format"
              alt="seafood soup"
             />
             <img src="https://afag.imgix.net/siri-thai/interior.jpeg?h=450&w=1140&fit=crop&auto=format"
             alt="interior"
           />
            <img src="https://afag.imgix.net/siri-thai/banner-1.jpg?h=450&w=1140&fit=crop&auto=format"
              alt="sample dish"
             />
             <img src="https://afag.imgix.net/siri-thai/grilled-salmon-with-teriyaki-sauce-1.jpg?h=450&w=1140&fit=crop&auto=format"
             alt="grilled salmon"
           />
            <img src="https://afag.imgix.net/siri-thai/banner-2.jpg?h=450&w=1140&fit=crop&auto=format"
              alt="sample dish"
             />
             <img src="https://afag.imgix.net/siri-thai/summer-rolls-2.jpg?h=450&w=1140&fit=crop&auto=format"
             alt="summer rolls"
           />
            <img src="https://afag.imgix.net/siri-thai/banner-3.jpg?h=450&w=1140&fit=crop&auto=format"
              alt="sample dish"
             />

             {/* <img src="https://afag.imgix.net/sidewalk-grill/hero-1.jpg?h=450&w=1000&fit=crop&auto=format"
               alt="assorted mediterraneon food on table"
              /> */}
          </Carousel>
        </div>

        <div className="site-description">
          <div className="description">
            <div
              style={{
                maxWidth: "150px",
              }}
               className="logo">
              <img
                src="https://afag.imgix.net/siri-thai/logo-1.png?w=300&auto=format" alt=""/>
            </div>
            <div className="text">
              <p>Serving aromatic Thai dishes for over a decade.</p>
            </div>
          </div>
          <p
          className="order-link"
          >Order Pickup / Delivery</p>
          <p className="order-link">
            <Link to="/order" className="order-online-button">
              Pay In Person / Pay Ahead
            </Link>
          </p>
     
          
        </div>

        <div className="location-info">
          <div className="text">
            <div className="location">
              <h2>
                Location
              </h2>
              <p>2730 W. Burbank Blvd.<br/>Burbank, CA 91505</p>
            </div>
            <div className="telephone">
              <h2>
                Telephone
              </h2>
              <p><a href="tel:+18188428222">(818)842-8222</a></p>

              <h2>
                Fax
              </h2>
              <p>818.861.7459</p>
            </div>
            <div className="hours-section">
              <h2>Hours</h2>
              <p>Dine In, Takeout, Delivery</p>
              <div className="hours-details">
                <p className="days">Sun-Thu</p>
                <p className="hours">11am - 3pm</p>
                <p className="hours">5:30pm - 9:30pm</p>
                <hr />
                {/* <p className="hours">(last order 9:30pm)</p> */}
                <p className="days">Fri</p>
                
                <p className="hours">11am - 3pm</p>
                <p className="hours">5:30pm - 10pm</p>
                <hr />

                <p className="days">Sat</p>
                
                
                <p className="hours">5:30pm - 10pm</p>
                <hr />
                {/* <p className="hours">(last order 9:30pm)</p> */}
              </div>
              <p>**Last Order 30 mins before closing</p>
            </div>
          </div>

        </div>

        <div className="review">
          <div className="logo"><img
              className="la-times-logo"
              style={{height: "25px"}}
              src={latimeslogo}
              alt="— Los Angeles Times"/></div>
          Greenbaum Kasson:
          <blockquote>
            Siri Thai Cuisine — where curries are delicious and ‘hot’ means hot
          </blockquote>

          <p
            style={{textAlign: 'center'}}
            >
            <a href="https://www.latimes.com/socal/burbank-leader/tn-blr-me-review-siri-thai-cuisine-20160901-story.html">Read the Review</a>
          </p>
        </div>

        {/* <div className="top-menu">
          <AutoMenu></AutoMenu>
        </div> */}
        {/* <div className="info">

          <p>Open Everyday: 11am-10pm</p>
          <p>
            <Link to="/order" className="order-online-button">Order Pickup / Delivery</Link>
          </p>

          <p>1727 N. Vermont Ave, #102<br/>
            Los Angeles, CA 90027</p>

          <p><a href="tel:+13236693354">323.669.3354</a></p>
        </div>
        <div className="text-section">
          <div className="header">
            <div className="deco">
              <img src="https://afag.imgix.net/sidewalk-grill/floral-icon.png?auto=format" alt=""/>
              <h1>
                <div className="title">welcome</div>
                <br/>
                <span className="subtitle">Where great food meets great people</span>
              </h1>

            </div>
          </div>
          <div className="content">
            <p>When hunger hits you, then let us take care of you. Come and taste some great food from the full menu. Our hormone and antibiotic free meats are marinated, seasoned to perfection and grilled over an open flame, a proven method for low fat grilling.</p>
            <p>Enjoy the taste of our homemade sides and sauces, freshly prepared daily. Choose from a selection of salads, wraps or plates and enjoy the decadent aroma with your friends, family or co-workers. Come visit us today and have a taste.</p>
          </div>
        </div> */}



      </div>







    );

}


export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-header">
        
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  console.log('triggered!!!!')
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  // const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  // const minutes = now.getMinutes() // 0 to 59
  // const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const lunchHours = (
    (hours >= 11) && (hours < 15)
  ) || (hours >= 22)

  const isLunchMenu = tag.indexOf('lunch') !== -1     // m-f 11am-3pm

  if (isLunchMenu) { 
    if (lunchHours) {
      return true
    }
    return false 
  }
  // if (isDinnerSubmenu && !showLunchMenu) { return true }

  return true
}
