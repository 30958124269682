import React from 'react'
// import './specials.scss'
// import AlcoholNotice from '../utils/AlcoholNotice'
import AutoMenu from '../../react/AutoMenu'
import {Link} from 'react-router-dom'
export default function () {
  return(
    <div className="wine-page">
      {/* <div className="top-banner" style={{backgroundImage: "url(https://afag.imgix.net/harvest-moon/wine-and-beer.jpg?w=1200)"}}>
        <div className="text">
          <h1>Wine and Beer</h1>
        </div>
      </div> */}

      {/* <AlcoholNotice></AlcoholNotice> */}

      <AutoMenu disableOrder/>

      {/* <div className="full-menu-link-wrapper">
        <Link to="/order" className="button">Go to Full Menu</Link>
      </div> */}
    </div>
  )
}
