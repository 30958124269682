
import * as Components from "./Siri"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "siri"
}

